.card {
    width: auto;
    background-color: white;
    border-radius: 10px;
    margin: 6px 0 6px 0;
    font-family: 'Roboto', sans-serif;
}

.container {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    padding: 100px;
}

.content {
    padding: 2px 4px;
    font-family: 'Roboto', sans-serif;
    /*width: 270px;*/
}

.header {
    padding: 5px 5px 0 5px;
}

.headerTitle {
    font-size: 16px;
    font-weight: 600;
}

.list {
    list-style-type: none;
    padding: unset !important;
    width: 250px;
}

.listItem {
    padding: 2px 0 2px 0;
    display: flex;
    /*align-items: center;*/
}

.listItem:hover,
.listItem:active {
    background-color: lightgrey;
}

.itemTitle {
    margin-left: 6px;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.itemText {
    margin-left: 6px;
    font-size: 14px;
    margin-right: 16px;
    font-weight: 500;
}

.itemSubText {
    margin-left: 6px;
    font-size: 12px;
    margin-right: 16px;
}

.itemIcon {
    color: blue;
    font-size: 16px;
}
