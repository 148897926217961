/*********************/
/****** Buttons ******/
/*********************/

.ui.primary.button,
.ui.primary.buttons .button {
    /* rgb(27,60,113) = #1b3c71 = Theme Color */
    background: rgb(27, 60, 113) !important;
}


.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
    /* rgb(27,60,113) * .9 = rgba(24,50,102) */
    background: rgb(24, 50, 102) !important;
}

/* Enabled title props on disabled buttons. */
.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
    pointer-events: auto !important;
}

/*******************/
/****** Icons ******/
/*******************/

/*BLUE*/
i.blue.icon {
    color: #1b3c71 !important;
}

/********************/
/****** Inputs ******/
/********************/

/*input:disabled,*/
/*.ui.form .disabled.field,*/
/*.ui.form .disabled.fields .field,*/
/*.ui.form .field :disabled {*/
/*    opacity: 1 !important;*/
/*}*/


/********************/
/****** Labels ******/
/********************/

/*RED*/
.ui.red.label,
.ui.red.labels .label {
    background: #ff647c !important;
}

/*GREEN*/
.ui.green.label,
.ui.green.labels .label {
    background: #00c48c !important;
}

/*BLUE*/
.ui.blue.label,
.ui.blue.labels .label {
    background: #1b3c71 !important;
}

/********************/
/****** Modals ******/
/********************/

/* Modal Background */
.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

/*******************/
/****** Table ******/
/*******************/

.ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/***************************/
/****** Toggle Switch ******/
/***************************/

/* Toggle Switch Color */
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background: #1b3c71 !important;
}
