body {
    margin: 0;
    padding: 0;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tostify-position {
    bottom: 50%;
}

@media only screen and (max-width: 1366px) {
    html {
        font-size: 10px !important;
    }

    body {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 1600px) {
    html {
        font-size: 11px !important;
    }

    body {
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 1920px) {
    html {
        font-size: 13px !important;
    }

    body {
        font-size: 13px !important;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
    color: #000;
}
